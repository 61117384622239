import Axios from 'axios'
import urls from './url'
import Vue from 'vue'
let _this = new Vue()
Axios.defaults.timeout = 30000

Axios.interceptors.response.use(function (response) {
    if(response.data.type=='application/octet-stream') {
        return response.data;
    }
    if (response.data.code != 0) {
        _this.$notify.warning({
            title: '警告',
            message: response.data.msg
        })
        if (response.data.code == 199) {
            location.assign('/#/login')
        }
        return Promise.reject(response.data);
    }
    return response.data;
},function (error) {
    _this.$notify.error({
        title: '警告',
        message: '请求超时'
    })
});

let post = (urlName, params, query, val) => {
    let url = urls[urlName]
    if (query) {
        if(val) {
            url += '/' + val
        }else {
            url += '?'
            for (let key in query) {
                url += `${key}=${query[key]}&`
            }
        }
    }
    return Axios.post(url, params)
}

let get = (urlName, query) => {
    let params = {params: query}
    return Axios.get(urls[urlName], params)
}

let exportRecord = (urlName, params) => {
    let url = urls[urlName]
    return Axios({
        method: 'post',
        url: url,
        data: params,
        responseType: 'blob',
    })
}

export default {
    post: post,
    get: get,
    exportRecord: exportRecord,
}