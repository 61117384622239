import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import ajax from './api'
import directive from './js/directive'
import { Graph } from '@antv/x6'
import CodeEditor from 'bin-code-editor'
import ws from './utils/ws'
import dataV from '@jiaminghi/data-view'

import 'element-ui/lib/theme-chalk/index.css';
import './css/style.less'
import '@/assets/font/font.css'

directive(Vue)
Vue.config.productionTip = false
Vue.prototype.$ajax = ajax;
Vue.prototype.Graph = Graph
Vue.prototype.$globalWs = ws
Vue.prototype.$CURRENT_MAP = 'TDT'

Vue.use(ElementUI);
Vue.use(CodeEditor);
Vue.use(dataV)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
